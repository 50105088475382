






























import { Vue, Component } from 'nuxt-property-decorator';
import { TopicCard } from '@/interfaces/topic-card';
import TopicPreview from '@/components/preview/TopicPreview.vue';
import MultiSecondHeadline from '@/components/base/MultiSecondHeadline.vue';
import { ScreenSize } from '@/plugins/event-listener.plugin';

/**
 * Maps each screen size to an amount of cards which should be displayed initially.
 */
const screenSizeCardInitialCountMap = {
  [ScreenSize.MOBILE]: 4,
  [ScreenSize.SMALL]: 4,
  [ScreenSize.MEDIUM]: 6,
  [ScreenSize.LARGE]: 6,
  [ScreenSize.EXTRA_LARGE]: 16,
};

/**
 * Maps each screen size to an amount of cards which should be added.
 */
const screenSizeCardAppendCountMap = {
  [ScreenSize.MOBILE]: 2,
  [ScreenSize.SMALL]: 2,
  [ScreenSize.MEDIUM]: 4,
  [ScreenSize.LARGE]: 4,
  [ScreenSize.EXTRA_LARGE]: 8,
};

/**
 * TopicFields.vue
 *
 * This component is used for rendering the topic fields in the homepage
 * It is a dedicated component because of higher readability and maintainability
 * It will read the data from vuex store and render them on homepage
 */
@Component({
  name: 'TheTopicFields',
  components: { TopicPreview, MultiSecondHeadline },
})
export default class TheTopicFields extends Vue {
  appendNumber = 0;

  /**
   * Returns if the 'more' button is visible or not
   */
  get isButtonVisible(): boolean {
    return this.availableTopics.length > this.topicsToShow.length;
  }

  /**
   * Returning the initial number of cards which should be used depending on the screen size.
   */
  get cardsToPresent(): number {
    return screenSizeCardInitialCountMap[this.$clientDimensions.screenSize];
  }

  /**
   * Returns a sorted array of all available topics.
   */
  get availableTopics(): TopicCard[] {
    return [
      {
        title: 'Arbeit & Ruhestand',
        img: {
          meta: { alt: 'Hobel', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/arbeit&ruhestand.jpg',
            url: '/sites/default/files/images/arbeit&ruhestand.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Arbeit &amp; Ruhestand“ befasst sich mit verschiedensten Sozialleistungen für Bürgerinnen und Bürger.</p>\n',
        topicId: 'e72b44c8-1835-4588-9627-f0820696996b',
        weight: 0,
      },
      {
        title: 'Bauen & Wohnen',
        img: {
          meta: { alt: 'Haus auf Bauplänen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/bauen&wohnen.jpg',
            url: '/sites/default/files/images/bauen&wohnen.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Bauen &amp; Wohnen“ umfasst die drei Lebens- und Geschäftslagen „Bauen &amp; Immobilien“, „Hausbau &amp; Immobilienerwerb“ sowie „Wohnen &amp; Umzug“.</p>',
        topicId: '5cf6f3b3-c20f-4ce0-ad73-903f9e65ffad',
        weight: 1,
      },
      {
        title: 'Bildung',
        img: {
          meta: { alt: 'Bücher mit Stift', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/bildung.jpg',
            url: '/sites/default/files/images/bildung.jpg',
          },
        },
        description:
          '<p>Das Themenfeld bündelt Leistungen in den vier Lebenslagen „Schule“, „Weiterbildung“, „Studium“ und „Berufsausbildung“.</p>',
        topicId: '783d5e8a-5b7f-4c0e-8d2f-722c7c5d3e63',
        weight: 2,
      },
      {
        title: 'Einwanderung, Aufenthalt, Integration und Staatsangehörigkeit',
        img: {
          meta: { alt: 'Arme mit dem Daumen oben', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/einwanderung.jpg',
            url: '/sites/default/files/images/einwanderung.jpg',
          },
        },
        description:
          '<p><span><span>Das Themenfeld „Einwanderung, Aufenthalt, Integration und Staatsangehörigkeit“ fasst sämtliche Leistungen zusammen, die die Einreise und den<br />\nAufenthalt in der Bundesrepublik betreffen. Dieses Spektrum betrifft ausländische und eingebürgerte Personen sowie Staatenlose.</span></span></p>',
        topicId: '2cd565e1-6936-4ba7-8e03-6c3b6d7ac8e2',
        weight: 3,
      },
      {
        title: 'Engagement & Hobby',
        img: {
          meta: { alt: 'Kinder halten Gegenstände hoch', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/engagement&hobby.jpg',
            url: '/sites/default/files/images/engagement&hobby.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Engagement &amp; Hobby“ umfasst die fünf Lebenslagen „Bootsbesitz“, „Fischen und Jagen“, „Engagement und Beteiligung“, „Tierhaltung“ und „Veranstaltung durchführen“.</p>',
        topicId: 'f2041819-d8d3-4c88-aca8-170e04eda1fb',
        weight: 4,
      },
      {
        title: 'Familie & Kind',
        img: {
          meta: { alt: 'Vorlesen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/familie&kind.jpg',
            url: '/sites/default/files/images/familie&kind.jpg',
          },
        },
        description:
          '<p>Alle Verwaltungsleistungen, die das Familienleben direkt betreffen – in Lebenslagen wie „Geburt“, „Ehe“, „Kinderbetreuung“, aber auch „Adoption“, „Scheidung“ und „Trennung mit Kind“.</p>',
        topicId: 'fa831c2d-44d9-4cc6-baa4-d20b726f9019',
        weight: 5,
      },
      {
        title: 'Forschung & Förderung',
        img: {
          meta: { alt: 'Mikroskop', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/forschung&förderung.jpg',
            url: '/sites/default/files/images/forschung&förderung.jpg',
          },
        },
        description:
          '<p>Das Themenspektrum reicht von der Erlaubnis zur Herstellung von Arzneimitteln über die Genehmigung von Pflegesätzen, die Förderung von Forschung, Kultur und Wirtschaft bis zur Zulassung zum Börsenhandel.</p>',
        topicId: '959e146e-6f6a-407d-9e6b-f91456f57593',
        weight: 6,
      },
      {
        title: 'Gesundheit',
        img: {
          meta: { alt: 'Würfel mit Gesundheitssymbolen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/gesundheit.jpg',
            url: '/sites/default/files/images/gesundheit.jpg',
          },
        },
        description:
          '<p>Neben den Bereichen der Gesundheitsvorsorge und Heilbehandlung, umfassen die über 60 OZG-Leistungen des Themenfelds zusätzlich die Gebiete der Pflege, Leistungen für Menschen mit Behinderung und die Hinterbliebenenversorgung.</p>',
        topicId: '4edcdc5c-bc0b-4d27-a893-ac4ddb548642',
        weight: 7,
      },
      {
        title: 'Mobilität & Reisen',
        img: {
          meta: { alt: 'Ladesäule für E-Autos', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/mobilität&reisen.jpg',
            url: '/sites/default/files/images/mobilität&reisen.jpg',
          },
        },
        description:
          '<p>Mobilität ist ein zentraler Faktor für Wirtschaft und Gesellschaft in Nordrhein-Westfalen. Unabhängig davon, ob Sie privat oder beruflich auf unseren Straßen, unseren Schienen oder in der Luft unterwegs sein wollen: Hier finden Sie Informationen rund um Fahrzeuge, Führerscheine, Logistik und Auslandsaufenthalte.</p>',
        topicId: 'feb974fd-90ba-4c8d-ac34-7630e2d2541e',
        weight: 8,
      },
      {
        title: 'Querschnittsleistungen',
        img: {
          meta: { alt: 'Menschen gehen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/querschnittsleistungen.jpg',
            url: '/sites/default/files/images/querschnittsleistungen.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Querschnittleistungen“ befasst sich mit grundlegenden Verwaltungsleistungen, insbesondere aus den Bereichen des Nachweis-, Melde- und Registerauskunftswesens. Leistungen in diesem Themenfeld sind oft relevant als Voraussetzung für eine spätere Beantragung bzw. den Bezug von Verwaltungsleistungen aus anderen Themenfeldern.</p>',
        topicId: 'ed579216-8831-4467-a61c-3db2ddd38d37',
        weight: 9,
      },
      {
        title: 'Recht & Ordnung',
        img: {
          meta: { alt: 'Justizia', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/recht&ordnung.jpg',
            url: '/sites/default/files/images/recht&ordnung.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Recht &amp; Ordnung“ befasst sich mit Leistungen aus den Bereichen gerichtliche und außergerichtliche Verfahren, Naturkatastrophen, Rechtsverstöße, Hilfen für Opfer sowie Fundsachen und Notfallrettung.</p>',
        topicId: '5fb5512a-94b0-4e71-9f3b-a655206b545b',
        weight: 10,
      },
      {
        title: 'Steuern & Zoll',
        img: {
          meta: { alt: 'Zoll Douane Schild', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/steuern&zoll.jpg',
            url: '/sites/default/files/images/steuern&zoll.jpg',
          },
        },
        description:
          '<p>Im Folgenden finden Sie Informationen zu den Themen „Steuern und Abgaben“, „Auslandsgeschäft“ sowie „Steuererklärung“.</p>',
        topicId: 'ad6ca929-ac4c-421a-b476-0fc05b5003cb',
        weight: 11,
      },
      {
        title: 'Umwelt',
        img: {
          meta: { alt: 'Berglandschaft', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/umwelt.jpg',
            url: '/sites/default/files/images/umwelt.jpg',
          },
        },
        description:
          '<p>Das Themenfeld „Umwelt“ umfasst Leistungen, die primär von Unternehmen oder Berufsausübenden, in einigen Fällen aber auch von Bürgerinnen und Bürgern in Anspruch genommen werden. Die Leistungen sind auf die beiden weit gefassten Geschäftslagen „Anlagen und Stoffe“ sowie „Abfall und Umweltschutz“ verteilt, betreffen neben den klassischen Umweltmedien aber beispielsweise auch Leistungen im Bereich von Naturschutz, Land- und Forstwirtschaft und Veterinärwesen.</p>',
        topicId: '162621e5-5481-4229-97a2-e485e4ecb926',
        weight: 12,
      },
      {
        title: 'Unternehmensführung & -entwicklung',
        img: {
          meta: { alt: 'Taschenrechner und Vernetzungen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/unternehmensführung.jpg',
            url: '/sites/default/files/images/unternehmensführung.jpg',
          },
        },
        description:
          '<p>In dem Themenfeld „Unternehmensführung &amp; -entwicklung“ sind die Leistungen auf acht Geschäftslagen verteilt, die vom Unternehmensstart über Arbeitgebersein, Gewährleistung der Arbeitssicherheit, Teilnahme an Ausschreibungen bis hin zur Unternehmensauflösung reichen.</p>',
        topicId: '06c76712-e3a0-4ad6-9edc-c776534123dc',
        weight: 13,
      },
    ];
  }

  /**
   * Returning the topics which should be rendered based on the screen size (cardsToPresent).
   */
  get topicsToShow(): TopicCard[] {
    return this.availableTopics.slice(0, this.cardsToPresent + this.appendNumber);
  }

  /**
   * This method will be triggered by the 'more' button and add 2 rows of cards (2, 4, 8) per click
   */
  appendMoreCards(): void {
    this.appendNumber += screenSizeCardAppendCountMap[this.$clientDimensions.screenSize];
  }
}
