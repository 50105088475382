








/**
 * MultiSecondHeadlineComponent.vue
 *
 * Customized headline component for h2 level multi headline
 *
 * Properties:
 *      - firstHeadline: first headline
 *      - secondHeadline: seconde headline
 */
import { Vue, Component, Prop } from 'nuxt-property-decorator';
@Component({
  name: 'MultiSecondHeadline',
})
export default class MultiSecondHeadlineComponent extends Vue {
  @Prop({ required: true }) readonly firstHeadline!: string;
  @Prop() readonly secondHeadline?: string;
}
