




























import { Vue, Component } from 'nuxt-property-decorator';
import TheCurrentInfoComponent from '@/components/body/TheCurrentInfo.vue';
import SearchComponent from '@/components/search/Search.vue';
import TheFrequentSearchBarComponent from '@/components/search/TheFrequentSearch.vue';
import { StageImage } from '@/interfaces/stage-image';
import TopicFieldsComponent from '@/components/body/TheTopicFields.vue';
import ThePartnerPortalsComponent from '@/components/body/ThePartnerPortals.vue';
import ImageLoaderComponent from '@/components/base/ImageLoader.vue';
import { ImageLoadType, Image } from '@/interfaces/atomic/image/image';
import { PageHead } from '@/interfaces/page-head';
import AppIconComponent from '@/components/svg/AppIcon.vue';

/**
 * index.vue
 *
 * This is the entry-point, the start page of this project
 */
@Component({
  components: {
    AppIcon: AppIconComponent,
    TheCurrentInfo: TheCurrentInfoComponent,
    SearchBar: SearchComponent,
    TheFrequentSearchBar: TheFrequentSearchBarComponent,
    TopicFields: TopicFieldsComponent,
    ThePartnerPortals: ThePartnerPortalsComponent,
    ImageLoader: ImageLoaderComponent,
  },
})
export default class Home extends Vue {
  head(): PageHead {
    return {
      title: this.$t('title.home'),
    };
  }

  images: StageImage[] = [];
  currentInformationIsAvailable = false;

  async fetch(): Promise<void> {
    await this.fetchStageImage();
  }

  mounted(): void {
    this.$root.$on('currentInformationIsAvailable', this.getCurrentInformationAvailability);
  }

  beforeDestroy(): void {
    this.$root.$off('currentInformationIsAvailable', this.getCurrentInformationAvailability);
  }

  getCurrentInformationAvailability(isAvailable: boolean): void {
    this.currentInformationIsAvailable = isAvailable;
  }

  /**
   * This method is used to fetch the stage image from CMS
   *
   * It is extracted from the fetch method because of better readability and testability
   */
  fetchStageImage(): void {
    this.images = [
      {
        id: '17203197-0000-4778-87e0-f9221d2c440c',
        fieldMediaImage: {
          meta: { alt: 'Public innovation Lab ServicePortal', title: '', width: 2000, height: 800 },
          imgLink: { value: 'public://images/Start.png', url: '/sites/default/files/images/Start.png' },
        },
      },
    ];
  }

  /**
   * get one random image from the image list to the local variable
   */
  get randomImg(): Image | undefined {
    return this.images.length > 0
      ? this.images[Math.floor(Math.random() * this.images.length)].fieldMediaImage
      : undefined;
  }

  /**
   * Get type for image loader
   */
  get imageLoadType(): ImageLoadType {
    return ImageLoadType.header;
  }

  /**
   * topicFieldClass()
   * is a computed method to set the margin
   * it's necessary because it can be happen that
   * the current information has no content or is out dated
   */
  get topicFieldClass(): string {
    return this.currentInformationIsAvailable ? 'xl:mt-24' : '';
  }
}
