/**
 * Type of frequent search node
 */
export enum FrequentSearchNodeType {
  LEBENSLAGE = 'lebenslage',
  LEISTUNG = 'leistung',
  INHALTS_SEITE = 'page',
}

/**
 * Frequent Search
 */
export interface FrequentSearchResult {
  /**
   * The tile of the search result
   */
  title: string;
  /**
   * Stands for the Universally Unique Identifier for this search result
   */
  uuid: string;
  /**
   * Node type
   *
   * @see {@link FrequentSearchNodeType}
   */
  type: FrequentSearchNodeType;
  /**
   * VSM id for leistung node
   */
  // eslint-disable-next-line camelcase
  field_vsm_leistung_id?: string;
}

/**
 * interface for frequent search endpoint
 */
export interface FrequentSearch {
  status: string;
  data?: FrequentSearchResult[];
}
