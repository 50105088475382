/**
 * Represents the CMS model for holding image url
 *
 * TODO DETOZGNRW-2203 remove this interface
 */
export interface ImageLink {
  /**
   * The url of this image
   */
  url: string;
  value?: string;
}

/** interface for image meta data */
export interface MetaInformation {
  /**
   * alt of this image
   */
  alt?: string;
  /**
   * Image title
   */
  title?: string;
  /**
   * Image width
   */
  width?: number;
  /**
   * Image height
   */
  height?: number;
}

/** type for cms ImageStyleUri */
type ImageStyleUriKey = 'large' | 'mediaLibrary' | 'medium' | 'thumbnail' | 'original';
export type ImageStyleUri = Partial<Record<ImageStyleUriKey, string>>;

/** Interface for cms Image */
export interface Image {
  /**
   * Image meta data
   *
   * @see {@link MetaInformation}
   */
  meta?: MetaInformation;
  /**
   * Link to original size image
   *
   * @see {@link ImageLink}
   */
  imgLink?: ImageLink;
  /**
   * Links to image styles image
   *
   * @see {@link ImageStyleUri}
   */
  imageStyleUri?: ImageStyleUri[];
}

/** interface for media image */
export interface FieldMediaImage {
  /**
   * CMS response attribute named fieldMediaImage1
   * It represents the image which is requested
   *
   * @see {@link Image}
   */
  fieldMediaImage1: Image;
}

/**
 * Image types
 */
export enum ImageLoadType {
  /**
   * Load image for thumbnail view
   */
  thumbnail,
  /**
   * load image for teaser view
   */
  teaser,
  /**
   * load image for header view
   */
  header,
}

/** current image sizes exposed by CMS */
export enum ImageSize {
  large = 'large',
  mediaLibrary = 'mediaLibrary',
  medium = 'medium',
  thumbnail = 'thumbnail',
  original = 'original',
}

/**
 * Simplified image interface, used when making specific api calls
 */
export interface SimpleImage {
  alt: string | null;
  url: string | null;
}
