































/**
 * TheCurrentInfoComponent.vue
 *
 * This component includes the current information of the Serviceportal
 *
 * The fetchCurrentInfo will fetch the current information from CMS
 * And The data will be mapped and presented in this component
 *
 */

import { Vue, Component } from 'nuxt-property-decorator';
import { ContentIdRouteParams, RouteNames } from '@generated/routeNames';
import { CurrentInformation } from '@/interfaces/current-information';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import MultiSecondHeadlineComponent from '@/components/base/MultiSecondHeadline.vue';
import NavigationLink from '@/components/base/NavigationLink.vue';
@Component({
  name: 'TheCurrentInfo',
  components: {
    AppIcon: AppIconComponent,
    MultiSecondHeadline: MultiSecondHeadlineComponent,
    NavigationLink,
  },
})
export default class TheCurrentInfoComponent extends Vue {
  currentInformation: CurrentInformation = {
    headline: 'Aktuelle Informationen zum Public Innovation Lab',
    secondaryHeadline: '',
    content: {
      value:
        '<p>Im Public Innovation Lab der Deutschen Telekom zeigen wir eine durchg&auml;ngige, automatisierte Verwaltungsdigitalisierung vom Antrag, &uuml;ber die Sachbearbeitung bis zum Bescheid. Wie sehen einfach handhabbare und zeitgem&auml;&szlig;e digitale Verwaltungsdienstleistungen, nutzerorientiert und medienbruchfrei für B&uuml;rger*innen und Unternehmen aus? Wie kann der Sachbearbeiter von Routineaufgaben entlastet werden? Wie l&auml;sst sich das Once-Only-Prinzip oder der R&uuml;ckkanal zum B&uuml;rger*in gestalten. Welchen Mehrwert bringt die Einbindung von Wallets? Wie kann die Umsetzungsgeschwindigkeit mit Hilfe von Low-Code Ans&auml;tzen gesteigert werden?</p>',
    },
    page: null,
  };

  isVisible = true;

  get headline(): string {
    return this.currentInformation.headline;
  }

  get secondaryHeadline(): string {
    return this.currentInformation.secondaryHeadline;
  }

  get content(): string {
    return this.currentInformation.content.value;
  }

  get pageId(): string | null {
    return this.currentInformation.page;
  }

  get contentPath(): string | null {
    return this.pageId
      ? this.localePath({
          name: RouteNames.CONTENT_ID,
          params: { [ContentIdRouteParams.ID]: this.pageId },
        })
      : null;
  }

  toggleVisibility(toggleState: boolean): void {
    this.isVisible = toggleState;
    this.$root.$emit('currentInformationIsAvailable', toggleState);
  }
}
