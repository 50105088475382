























/**
 * ThePartnerPortals.vue
 *
 * This component is used for rendering the partner portals in the homepage
 * It is a dedicated component because of higher readability and maintainability
 * It will dispatch a vuex action to fetch the portal information
 * And then display (part of) them in the view
 */

import { Vue, Component } from 'nuxt-property-decorator';
import { RouteNames } from '@generated/routeNames';
import { PortalCard } from '@/interfaces/portal-card';
import { PartnerPortal } from '@/interfaces/partner-portal';
import PortalPreviewComponent from '@/components/preview/PortalPreview.vue';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import MultiSecondHeadlineComponent from '@/components/base/MultiSecondHeadline.vue';
import NavigationLink from '@/components/base/NavigationLink.vue';

@Component({
  name: 'ThePartnerPortals',
  components: {
    PortalPreview: PortalPreviewComponent,
    AppIcon: AppIconComponent,
    MultiSecondHeadline: MultiSecondHeadlineComponent,
    NavigationLink,
  },
})
export default class ThePartnerPortalsComponent extends Vue {
  // Store raw portals' information which is fetched from CMS
  primaryPortals: PartnerPortal[] = [
    {
      title: 'Pega',
      uuid: 'e468c3de-e983-4faf-b8ab-baacbfa1c9e3',
      image: { url: '/sites/default/files/portals/Link1.png', alt: 'Pega' },
      link: { uri: 'https://80.158.41.55/prweb/PRAuth/Buergerkonto', title: 'Pega' },
    },
    {
      title: 'Servicekonto',
      uuid: '80b35063-b50f-45ca-b789-16c2b303a984',
      image: { url: '/sites/default/files/portals/Link2.png', alt: 'Servicekonto Logo' },
      link: { uri: 'https://buergerkonto-showcase.t-systems-service.com/', title: 'Servicekonto' },
    },
  ];

  secondaryPortals: PartnerPortal[] = [
    {
      title: 'Public Innovation Lab',
      uuid: 'e468c3de-e983-4faf-b8ab-baacbfa1c9e4',
      image: { url: '/sites/default/files/images/Start.png', alt: 'Public Innovation Lab Logo' },
      link: {
        uri: 'https://public.telekom.de/unsere-loesungen/oeffentliche-verwaltung/public-innovation-lab',
        title: 'Public Innovation Lab',
      },
    },
    {
      title: 'CityKeyApp',
      uuid: 'ba77478a-abc8-4c0f-87ae-45be82d6983d',
      image: { url: 'https://citykey.app/images/citykey-logo.svg', alt: 'Logo CityKeyApp' },
      link: { uri: 'https://citykey.app/', title: 'CityKeyApp' },
    },
    {
      title: 'Servicekonto',
      uuid: '80b35063-b50f-45ca-b789-16c2b303a985',
      image: { url: 'https://public.telekom.de/resources/images/326214/icon2.png', alt: 'Servicekonto Logo' },
      link: {
        uri: 'https://public.telekom.de/unsere-loesungen/oeffentliche-verwaltung/servicekonto',
        title: 'Servicekonto',
      },
    },
    {
      title: 'Portallink',
      uuid: 'ba77478a-abc8-4c0f-87ae-44be82d6983d',
      image: { url: '/sites/default/files/portals/Link6.png', alt: 'Logo Portallink' },
      link: { uri: '', title: 'Portallink' },
    },
  ];

  // Store portalcards' information and will be presented on the view
  primaryPortalCards: PortalCard[] = [];
  secondaryPortalCards: PortalCard[] = [];

  /**
   * According to the uxpin design, we render maximum 4 cards on server side
   * If the total number of portal cards is bigger than 4, then a link will be presented
   * And it links to the Themenportal page
   */
  fetch(): void {
    this.initialPortalCards(this.primaryPortals, this.primaryPortalCards);
    this.initialPortalCards(this.secondaryPortals, this.secondaryPortalCards);
  }

  /**
   * get partner portal layout class for different card numbers
   * maximum 4 partner portal cards allowed in cms
   *
   * Note that the purge plugin can not identify classes with variables,
   * so that's why we have to add it for each case.
   * For more info see: https://confluence.t-systems-mms.eu/display/detozgnrw/Code+Documentation#CodeDocumentation-StylingusingTailwind
   */
  portalLayoutClass(length: number): string {
    switch (length) {
      case 1:
        return 'md:grid-cols-1 xl:grid-cols-1';
      case 2:
        return 'md:grid-cols-2 xl:grid-cols-2';
      case 3:
        return 'md:grid-cols-2 xl:grid-cols-3';
      case 4:
        return 'md:grid-cols-4';
      default:
        return 'md:grid-cols-2 xl:grid-cols-4';
    }
  }

  /**
   * Returns the localized path to the portals page.
   */
  get portalsPath(): string {
    return this.localePath(RouteNames.PORTALS);
  }

  // /**
  //  * fetch the 4 partner portals from cms
  //  */
  // fetchPortals(): Promise<PartnerPortals | undefined> {
  //   return this.$callApi<PartnerPortals>('fetch-partner-portals');
  // }

  /**
   * Initial Portal cards
   */
  initialPortalCards(portals: PartnerPortal[], cardsArray: PortalCard[]): void {
    for (const portal of portals) {
      const portalCard: PortalCard = {
        title: portal.title || '',
        portalId: portal.uuid || '',
        img: {
          meta: {
            alt: portal.image.alt,
          },
          imgLink: {
            url: portal.image.url,
          },
        },
        link: portal.link.uri || '',
      };
      cardsArray.push(portalCard);
    }
  }
}
