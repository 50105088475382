
















import { customMedia } from '@/config';
import { Image, ImageLoadType, ImageSize, ImageStyleUri } from '@/interfaces/atomic/image/image';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

/**
 * Image type to image size mapping; see drupal backend?
 *
 * The first value in ImageSize[] is the size to use for the smaller image the other for a larger image.
 * TODO DETOZGNRW-2419 determine the correct image mapping and image loading
 */
// const imgMapping: Partial<Record<ImageLoadType, ImageSize[]>> = {
//   [ImageLoadType.thumbnail]: [ImageSize.thumbnail, ImageSize.medium],
//   [ImageLoadType.teaser]: [ImageSize.medium, ImageSize.large],
// };

/**
 * This is the default image mapping. Use array index 0 for a small image and the other one for a large image
 */
const defaultImgMapping: ImageSize[] = [ImageSize.large, ImageSize.original];

/**
 * ImageLoader.vue
 *
 * This component is used to load an image.
 * If an error occurs during loading, a default png will be presented.
 *
 * Props:
 * * image - represents the readonly image object which is fetched from its parent
 * * imageType - a required image type enum (thumbnail, teaser, header)
 * * imageStyle - optional - gives the parent component the possibility to apply a customized style; it will use object-cover and object-center by default
 */
@Component({
  name: 'ImageLoader',
})
export default class ImageLoaderComponent extends Vue {
  // Default style for successful loading (used for topic preview image, circumstance preview image, stage image etc...)
  @Prop({ default: 'object-cover object-center' }) readonly imageStyle!: string;
  @Prop({ required: true }) readonly imageType!: ImageLoadType;
  @Prop() readonly image?: Image;

  // style for error image
  errorStyle = 'object-fill';
  // image loading error
  error = false;

  /**
   * If an error occurs, set the error flag and change the style
   */
  replaceByDefault(): void {
    this.error = true;
  }

  /**
   * Get maximum width from config.js for setting a breakpoint to decide which image size to load.
   * Till this size a smaller image will be loaded, otherwise the fallback (lager) image via img-tag is shown.
   */
  get maxWidth(): string {
    return customMedia['--max-md'];
  }

  /**
   * Check if there is an error and set the correct class names.
   */
  get finalStyle(): string {
    return this.error ? this.errorStyle : this.imageStyle;
  }

  /**
   * A getter method for loading a smaller image depending on its type.
   */
  get smallerImage(): string {
    return this.getImageUrl(0);
  }

  /**
   * A getter method for loading a larger image depending on its type.
   */
  get largerImage(): string {
    return this.getImageUrl(1);
  }

  /**
   * Return the image alt attribute
   * Teaser <img> tag should get an empty alt attribute (alt=””), so the picture is ignored by the screen reader
   * @see {@link https://jira.t-systems-mms.eu/browse/DETOZGNRW-904}
   */
  get imageAlt(): string {
    if (this.imageType === ImageLoadType.teaser) {
      return '';
    }
    return this.image?.meta?.alt || this.$i18n.t('label.defaultImage').toString() + ImageLoadType[this.imageType];
  }

  /**
   * get URL for an image by its image type (thumbnail, teaser, header)
   *
   * @param size - 0 for smaller and 1 for lager image
   */
  getImageUrl(size: 0 | 1): string {
    if (this.image && this.image.imgLink && this.image.imgLink.url && !this.error) {
      // const mapping = imgMapping[this.imageType];
      const imgUrl = this.getImageUrlBySize(defaultImgMapping[size]);
      return imgUrl || this.getFallbackImgUrl();
    }
    return this.getFallbackImgUrl();
  }

  /**
   * get a standart fall back image URL
   * https://pencilflip.medium.com/the-three-differences-between-require-and-import-in-node-js-f54f78f5936f
   */
  getFallbackImgUrl(): string {
    return require('@/assets/images/fallbackImg.png');
  }

  /**
   * get image url for a required size
   *
   * @param sizeImage - is the required Image size
   */
  getImageUrlBySize(sizeImage: ImageSize): string | undefined {
    if (this.image?.imageStyleUri && sizeImage !== ImageSize.original) {
      const element = this.image.imageStyleUri.find((el) => this.imageStyleUriElementIsSize(el, sizeImage));
      if (element) {
        return element[sizeImage];
      }
    }
    return this.image?.imgLink?.url;
  }

  /**
   * check if the element contains the required image size
   */
  imageStyleUriElementIsSize(element: ImageStyleUri, sizeImage: ImageSize): boolean {
    const keys = Object.keys(element);
    if (keys.length === 1) {
      return keys[0] === sizeImage;
    }
    return false;
  }
}
