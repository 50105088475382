

































/**
 * TopicPreview.vue
 *
 * This component is used to map the topic preview card
 * It accepts a property 'TopicCard' from its parent component
 * And then present it to UI
 *
 * Props:
 * * TopicCard:
 * * It is used for mapping the topic preview card data model
 * * It contains a title, a image and the corresponding description
 * * It also contains the topic Id for searching all related information for this topic
 */

import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { CircumstancesCircumstanceRouteParams, RouteNames, TopicsTopicRouteParams } from '@generated/routeNames';
import { TopicCard } from '@/interfaces/topic-card';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import ImageLoaderComponent from '@/components/base/ImageLoader.vue';
import NavigationLink from '@/components/base/NavigationLink.vue';
import { ImageLoadType, Image } from '@/interfaces/atomic/image/image';
import { CircumstanceCard } from '@/interfaces/circumstance-card';

@Component({
  name: 'TopicPreview',
  components: {
    AppIcon: AppIconComponent,
    ImageLoader: ImageLoaderComponent,
    NavigationLink,
  },
})
export default class TopicPreviewComponent extends Vue {
  @Prop({ required: true }) readonly cardprop!: TopicCard & CircumstanceCard;

  readonly maximalLength: number = 20;

  /**
   * According to the design, the '...' should be rendered the same as the title (bold and underscore)
   * Here we have to slice the title and append '...' manually
   */
  get cardTitle(): string {
    const title: string = this.cardprop.title;
    return title.length > this.maximalLength ? title.slice(0, this.maximalLength) + '...' : title;
  }

  get cardImage(): Image | undefined {
    return this.cardprop.img;
  }

  /**
   * Get type for image loader
   */
  get imageLoadType(): ImageLoadType {
    return ImageLoadType.teaser;
  }

  /**
   * Returns the localized path for the topic.
   */
  get topicPath(): string {
    return this.localePath({
      name: RouteNames.TOPICS_TOPIC,
      params: { [TopicsTopicRouteParams.TOPIC]: this.cardprop.topicId },
    });
  }

  /**
   * Returns the localized path for the circumstance.
   */

  get circumstancePath(): string {
    return this.localePath({
      name: RouteNames.CIRCUMSTANCES_CIRCUMSTANCE,
      params: { [CircumstancesCircumstanceRouteParams.CIRCUMSTANCE]: this.cardprop.circumstanceId },
    });
  }
}
