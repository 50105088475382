













































/**
 * Search.vue
 *
 * This component uses the SearchInputComponent and handle to search APIs. This component can be used on all pages
 * so we won't have to take about duplicate code.
 *
 * Property:
 *   - location: this property is used to generate unique ids in the children (e.g. homepage, footer, header, search) [string]
 *
 * Pages can mount it with:
 *   <search></search>
 */

import { Component, Prop } from 'nuxt-property-decorator';
import { BaseSearch } from '@/components/search/BaseSearch';
import SearchInputComponent from '@/components/base/SearchInput.vue';

@Component({
  name: 'SearchComponent',
  components: { SearchInput: SearchInputComponent },
})
export default class SearchComponent extends BaseSearch {
  @Prop({ default: 'homepage' }) readonly location!: string;
  @Prop({ type: Boolean }) readonly isInSearchPage!: boolean;

  created(): void {
    this.componentLocation = this.location;
  }

  mounted(): void {
    this.updateSearchInputs();
  }

  activated(): void {
    this.updateSearchInputs();
  }

  /**
   * updateSearchInputs()
   *
   * assign in dependence of the current page the search
   * keyword from the store to the component variables
   */
  updateSearchInputs(): void {
    if (this.componentLocation === 'results') {
      this.searchTopic = this.storedTopic;
    }
    this.searchLocation = this.storedLocation;
  }

  /**
   * searchTopicID()
   *
   * computed method to generate unique id for topic search
   */
  get searchTopicID(): string {
    return 'search-topic-' + this.location;
  }

  /**
   * searchLocationID()
   *
   * computed method to generate unique id for location
   */
  get searchLocationID(): string {
    return 'search-location-' + this.location;
  }

  /**
   * computed method is needed to disable/enable the search button
   * because of inheritance of BaseSearch we have to check if searchTopic.value
   * is defined to avoid error messages because cannot use length of undefined
   */
  get buttonDisabled(): boolean {
    if (this.searchTopic.value) {
      return this.searchTopic.value.length < 3;
    }
    return true;
  }
}
