



















/**
 * FrequentSearch.vue
 *
 * This component fetches the frequent searched results from CMS and presents it below the search bar
 *
 * Component call:
 *   <FrequentSearchBar />
 */

import { Vue, Component } from 'nuxt-property-decorator';
import { Location } from 'vue-router';
import {
  CircumstancesCircumstanceRouteParams,
  ContentIdRouteParams,
  RouteNames,
  ServiceLeikaidRouteParams,
} from '@generated/routeNames';
import { FrequentSearchResult, FrequentSearchNodeType } from '@/interfaces/frequent-search';
import AppIcon from '@/components/svg/AppIcon.vue';
import NavigationLink from '@/components/base/NavigationLink.vue';

/**
 * type for frequent search result ui related fields
 */
interface FrequentSearchUiResult {
  title: string;
  location: Location;
}

@Component({
  name: 'TheFrequentSearchComponent',
  components: { AppIcon, NavigationLink },
})
export default class TheFrequentSearchComponent extends Vue {
  // Variable to store the frequent searched results
  frequentSearchResults: FrequentSearchUiResult[] = [
    {
      title: 'Deutsche Staatsangehörigkeit Erteilung der Erlassung aus der deutschen Staatsangehörigkeit',
      location: {
        name: RouteNames.SERVICE_LEIKAID,
        params: { [ServiceLeikaidRouteParams.LEIKAID]: '99099008006001', authorityKey: '059000000000' },
      },
    },
    {
      title: 'Kraftfahrzeugsteuer',
      location: {
        name: RouteNames.SERVICE_LEIKAID,
        params: { [ServiceLeikaidRouteParams.LEIKAID]: '99099008006001', authorityKey: '059000000000' },
      },
    },
    {
      title: 'Deutsche Staatsangehörigkeit Genehmigung des Verzichts',
      location: {
        name: RouteNames.SERVICE_LEIKAID,
        params: { [ServiceLeikaidRouteParams.LEIKAID]: '99099008006001', authorityKey: '059000000000' },
      },
    },
  ];

  // async fetch(): Promise<void> {
  //   await this.fetchFrequentSearchResult();
  // }

  // /**
  //  * Fetch the frequent search data from CMS
  //  */
  // async fetchFrequentSearchResult(): Promise<void> {
  //   const resp = await this.$callApi<FrequentSearch>('frequent-search');
  //   if (resp && resp.data && resp.status === 'success') {
  //     this.frequentSearchResults = resp.data
  //       // map to title & location
  //       .map<[string, Location | undefined]>((searchResult) => [
  //         searchResult.title,
  //         this.getLocationForSearchResult(searchResult),
  //       ])
  //       // filter only those having a location
  //       .filter((values): values is [string, Location] => !!values[1])
  //       // map to our UI-structure
  //       .map<FrequentSearchUiResult>(([title, location]) => ({
  //         title,
  //         location,
  //       }));
  //   }
  // }

  /**
   * When we click one of the frequently searched result
   * The topic title will be recorded
   */
  onClick(frequentSearchResult: FrequentSearchResult): void {
    this.$searchModule.updateTopic({ value: frequentSearchResult.title, suggestion: null });
  }

  /**
   * gets the route Location for frequent search result
   */
  private getLocationForSearchResult(searchResult: FrequentSearchResult): Location | undefined {
    let location;
    switch (searchResult.type) {
      case FrequentSearchNodeType.LEBENSLAGE:
        location = {
          name: RouteNames.CIRCUMSTANCES_CIRCUMSTANCE,
          params: { [CircumstancesCircumstanceRouteParams.CIRCUMSTANCE]: searchResult.uuid },
        };
        break;
      case FrequentSearchNodeType.LEISTUNG:
        location = searchResult.field_vsm_leistung_id
          ? {
              name: RouteNames.SERVICE_LEIKAID,
              params: {
                [ServiceLeikaidRouteParams.LEIKAID]: searchResult.field_vsm_leistung_id,
                authorityKey: '059000000000',
              },
            }
          : undefined;
        break;
      case FrequentSearchNodeType.INHALTS_SEITE:
        location = { name: RouteNames.CONTENT_ID, params: { [ContentIdRouteParams.ID]: searchResult.uuid } };
        break;
      default:
        location = undefined;
    }
    return location;
  }
}
