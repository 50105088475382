





























/**
 * PortalPreview.vue
 *
 * This component is used to map the portal card component
 * It accepts a property 'PortalCard' from its parent component
 * And then present it in the UI
 *
 * Props:
 * * PortalCard: the model of the portal preview card
 * * It contains a title, an image link (internal) and an external link of the portal
 */

import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { PortalCard } from '@/interfaces/portal-card';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import ImageLoaderComponent from '@/components/base/ImageLoader.vue';
import { ImageLoadType, Image } from '@/interfaces/atomic/image/image';

@Component({
  name: 'PortalPreview',
  components: {
    AppIcon: AppIconComponent,
    ImageLoader: ImageLoaderComponent,
  },
})
export default class PortalPreviewComponent extends Vue {
  @Prop({ required: true }) readonly cardprop!: PortalCard;

  get cardTitle(): string {
    return this.cardprop.title;
  }

  get cardLink(): string {
    return this.cardprop.link;
  }

  get cardImage(): Image | undefined {
    return this.cardprop.img;
  }

  /**
   * Get type for image loader
   */
  get imageLoadType(): ImageLoadType {
    return ImageLoadType.teaser;
  }
}
